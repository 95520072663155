// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/footer/ic_close.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:751px){.qrcode_wrapper[data-v-e458a1f4]{display:none}}@media screen and (max-width:750px){.qrcode_wrapper[data-v-e458a1f4]{position:fixed;top:0;left:0;z-index:9999;width:100%;height:100%;background:rgba(0,0,0,.5)}.qrcode_wrapper .qrcode_container[data-v-e458a1f4]{background:#fff;border-radius:0.2rem;width:4.8rem;height:5.9rem;margin:2.36rem auto 0}.qrcode_wrapper .qrcode_container .img[data-v-e458a1f4]{width:4.4rem;height:4.4rem;margin:0.2rem}.qrcode_wrapper .qrcode_container .text[data-v-e458a1f4]{line-height:1;text-align:center}.qrcode_wrapper .qrcode_container .text-name[data-v-e458a1f4]{color:#000;font-weight:500;font-size:0.36rem}.qrcode_wrapper .qrcode_container .text-tips[data-v-e458a1f4]{margin-top:0.12rem;font-size:0.2rem;color:#333}.btn_close[data-v-e458a1f4]{width:0.5rem;height:0.5rem;margin:0.4rem auto 0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
