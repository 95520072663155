
export default {
  props: {
    // 组件样式
    styleObject: {
      type: Object,
      required: false,
      default: () => {},
    },
    // src: {
    //   type: String,
    //   default: () => {
    //     return ''
    //   },
    // },
  },
  data() {
    return {
      isVisible: false,
      src: '',
      tips: '',
    }
  },
  methods: {
    show(src, tips) {
      this.src = src
      this.tips = tips
      this.isVisible = true
    },

    hide() {
      this.isVisible = false
    },
  },
}
