import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=e54f253a&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=e54f253a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e54f253a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Imglazy: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/Imglazy.vue').default,LinkTo: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/LinkTo.vue').default,SvgIcon: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/SvgIcon.vue').default})
