
import { mapMutations, mapState } from 'vuex'
import QRCodeDialog from './QRCodeDialog.vue'
import CookieSettingDialog from './cookieSetting.vue'
import allUrls from '@/utils/config'
import footer from '@/modules/config/footer'

export default {
  components: { QRCodeDialog, CookieSettingDialog },
  data() {
    return {
      service: footer[this.$route.params.site].service,
      shareSubTitle: footer[this.$route.params.site].shareSubTitle,
      shareSubList: footer[this.$route.params.site].shareSubList,
      iuopUrl: allUrls.iuoploginUrl,
      site: this.$route.params.site,
      lang: this.$route.params.lang,
      shareList: [
        {
          name: 'wechat',
          tips: 'common.media.wechat',
          image: '~/assets/img/icons/wx.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_wechat.jpg',
        },
        {
          name: 'weibo',
          tips: 'common.media.weibo',
          image: '~/assets/img/icons/weibo.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_weibo.png',
        },
        {
          name: 'douyin',
          tips: 'common.media.douyin',
          image: '~/assets/img/icons/douyin.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_tiktok.png',
        },
        // {
        //   name: 'lingying',
        //   image: '~/assets/img/icons/lingyin.svg',
        //   qrcode: '~/assets/img/footer/ic_qrcode_lingying.png',
        // },
        {
          name: 'facebook',
          tips: 'common.media.facebook',
          image: '~/assets/img/icons/facebook.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_facebook.png',
        },
        // {
        //   name: 'tw',
        //   image: require('~/assets/img/icons/tw.png'),
        //   qrcode: '~/assets/img/footer/ic_qrcode_tw.png',
        // },
        {
          name: 'youbube',
          tips: 'common.media.youtube',
          image: '~/assets/img/icons/youbube.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_youbute.png',
        },
        {
          name: 'ins',
          tips: 'common.media.instagram',
          image: '~/assets/img/icons/ins.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_ins.png',
        },
        {
          name: 'tiktok',
          tips: 'common.media.tiktok',
          image: '~/assets/img/icons/douyin.svg',
          qrcode: '~/assets/img/footer/ic_qrcode_tiktok.png',
        },
      ],
      clientWidth: 0,
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      isHdFtMobile: (state) => state.isHdFtMobile,
      productMenus: (state) => state.productMenus,
    }),
    year() {
      return new Date().getFullYear()
    },
    isSc() {
      return /^zh[-_][a-zA-Z]+$/.test(this.locale)
    },
    isFooter150() {
      return this.clientWidth > 1200 && this.clientWidth < 1500
    },
    support() {
      return require(`@/router/${this.site}/support.js`).default || {}
    },
    aboutUs() {
      return require(`@/router/${this.site}/aboutUs.js`).default || {}
    },
    helperList() {
      return [
        {
          il8nName: 'navs.logisticsService',
          id: 1,
          children: this.productMenus,
          // [
          //   {
          //     il8nName: 'modules.logisticsService.product',
          //     path: '/product#product',
          //     params: { hash: 'product' },
          //   },
          //   {
          //     il8nName: 'modules.logisticsService.increment',
          //     path: '/product#increment',
          //     params: { hash: 'increment' },
          //   },
          //   {
          //     il8nName: 'modules.logisticsService.addition',
          //     path: '/product#addition',
          //     params: { hash: 'addition' },
          //   },
          // ],
        },
        this.getChildrenPath(this.support),
        this.getChildrenPath(this.aboutUs),
      ]
    },
    getOtherSite() {
      if (process.client) {
        if (window.isHideSFbuy) {
          return (
            this.service
              .otherSite(this.lang)
              // 暂时过滤sfbuy
              .filter((it) => it.name !== 'modules.about.homePageTitles.sfBuy')
          )
        } else {
          return this.service.otherSite(this.lang)
        }
      } else {
        return []
      }
    },
  },
  mounted() {
    this.clientWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.clientWidth = document.body.clientWidth
      })()
    }
    this.lang = this.$route.params.lang
  },
  methods: {
    ...mapMutations({
      setCookieTerms: 'SET_COOKIE_TERMS',
    }),
    routeChange(item) {
      this.$refs.QRCodeDialog.show(item.qrcode, item.text)
    },
    getAboutUrls() {
      return {}
    },
    // cookie 设置
    openCookieDialog() {
      this.setCookieTerms({
        title: this.$t('cookies.cookie'),
        show: true,
        type: 1,
      })
    },
    getChildrenPath(menu) {
      menu.children.map((me) => {
        if (!me.path) {
          me.path = me.children[0].path || ''
        }
        return me
      })
      return menu
    },
    getPopupContainer() {
      return this.$refs.wrapper
    },
  },
}
